<template>
  <div class="header-bar">
    <div class="step-bar" :style="{ width: privatePercentage + '%' }">
      <div class="step-bar-percentage">{{ privatePercentage }}%</div>
    </div>
    <main>
      <div class="money">
        <div>{{ privateMoney }}</div>
        <p>预估额度(元)</p>
      </div>
      <div class="step" v-show="step !== 14 && !loanInfoHasDefaultValue">
        <span>{{ step }}</span>
        <em>/13</em>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      privatePercentage: this.$getHeaderPercentage() || 50,
      privateMoney: this.$getHeaderMoney() || 5000,
      //定时器
      percentageTimer: null,
      moneyTimer: null,
      // 只展示用户姓名和身份证页面
      loanInfoHasDefaultValue: process.env.VUE_APP_LOAN_INFO_HAS_DEFAULT_VALUE
    }
  },
  props: {
    step: {
      type: Number,
      default: 1
    }
  },
  methods: {
    changeMoney() {
      if (this.moneyTimer) {
        clearInterval(this.moneyTimer);
        this.moneyTimer = null;
      };
      const val = this.$getHeaderMoney();
      if (!val || val - this.privateMoney > 7000 || val <= this.privateMoney) {
        return;
      };
      const calculateNum = 100;
      const time = 200 / Math.abs(val - this.privateMoney);
      this.moneyTimer = setInterval(() => {
        this.privateMoney += calculateNum;
        if (this.privateMoney === val) {
          clearInterval(this.moneyTimer);
          this.moneyTimer = null;
        }
      }, time * 100);
    },
    changePercentage() {
      if (this.percentageTimer) {
        clearInterval(this.percentageTimer);
        this.percentageTimer = null;
      };
      const val = this.$getHeaderPercentage();
      if (!val || val - this.privatePercentage > 7 || val <= this.privatePercentage) {
        return;
      };

      const calculateNum = 1;
      const time = 200 / Math.abs(val - this.privatePercentage);
      this.percentageTimer = setInterval(() => {
        this.privatePercentage += calculateNum;
        if (this.privatePercentage === val) {
          clearInterval(this.percentageTimer);
          this.percentageTimer = null;
        }
      }, time);
    }
  }
}
</script>

<style lang="less" scoped>
.step-bar {
  position: relative;
  width: 0;
  height: 4px;
  margin-bottom: 10px;
  background: linear-gradient(90deg, rgba(31, 95, 252, 0) 0%, #1f5ffc 100%);
  border-radius: 0px 2px 2px 0px;
  transition: 200ms linear;

  .step-bar-percentage {
    position: absolute;
    bottom: -16px;
    right: 0;
    font-size: 10px;
    font-weight: 400;
    color: #325ef3;
  }
}

main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0.6757rem 0 0.8108rem;

  .money {
    font-size: 26px;
    font-weight: 600;
    color: #325ef3;
    text-align: center;

    p {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }
  }

  .step {
    font-size: 26px;
    font-weight: 600;
    color: #333333;

    em {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
</style>