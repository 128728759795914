<template>
  <div id="select-page">
    <h1>{{ title }}</h1>
    <div class="options-container">
      <div
        v-for="item in options"
        :key="item"
        :class="getUserSelectDomClassName(item)"
        @click="doSelect(item)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentOption: ''
    }
  },
  props: {
    title: String,
    options: {
      type: Array,
      default() {
        return []
      }
    },
    propName: String,
    step: Number,
    percentage: Number
  },
  methods: {
    // 获取用户当前点击项的类名
    getUserSelectDomClassName(value) {
      if (this.currentOption === value) {
        return 'flex-y-center option option-is-selected'
      } else {
        return 'flex-y-center option'
      }
    },
    doSelect(item) {
      if (item.indexOf('无') === -1 && !this.currentOption) {
        const moneyArr = [3000, 5000, 7000];
        const number = Math.floor(Math.random() * 3);
        this.$setHeaderMoney(this.$getHeaderMoney() + moneyArr[number]);
      };
      this.$setHeaderPercentage(this.percentage);
      this.currentOption = item;
      const userInfo = this.$getUserInfo();
      userInfo[this.propName] = this.currentOption;
      this.$setUserInfo(userInfo);
      this.$emit('changeActive', this.step);
    },
  },
  created() {
    this.currentOption = this.$getUserInfo()[this.propName] || '';
  }
};
</script>
<style lang="less" scoped>
#select-page {
  width: 100vw;
  background: #ffffff;

  h1 {
    margin: 1.4054rem 0 0.9459rem 0;
    font-size: 16px;
    font-weight: 600;
    color: #2e2f31;
    text-align: center;
  }

  .options-container {
    width: 100%;
    padding: 0 1.1351rem 0 1.1622rem;

    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7.733rem;
      height: 1.333rem;
      margin-bottom: 0.5405rem;
      background: #f6f6f6;
      border-radius: 0.667rem;
      font-size: 14px;
      font-weight: 400;
      color: #2e2f31;
    }

    .option-is-selected {
      background: #325ef3 !important;
      color: #ffffff !important;
    }
  }
}
</style>